// Currently contains some of the part of the form step
// Idea is to move everything here at some point, to structure it properly
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components';
import { Step } from 'store/Step';
import { MaterialPlanTasksTable } from './MaterialPlanTasks'
import { MaterialPlanMaterialsTable } from './MaterialPlanMaterials'

// stores
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion';
import { ProductionRequest } from 'store/ProductionRequest'
import { Operator } from 'store/Operator'
// end stores

export const FullWidthTable = styled(Table)`
  width: 100%;
  table-layout: auto;
  tr td {
    border-top: 0px !important;
    overflow: hidden;
  }
  th, td {
    overflow-wrap: break-word;
    border-bottom: 0px !important;
  }
`;

/**
 * Checks if a materialPlanItem belongs to this workstation.
 * Returns true only if the item is assigned to this workstation and false otherwise.
 *
 * @param workStation
 * @param materialPlanItem
 */
export const isMyWorkstation = (workStationCode, materialPlanItem) => {
  if (materialPlanItem.externalWorkStation.isNew) {
    return false;
  }

  if (materialPlanItem.externalWorkStation.workStation.isNew) {
    return false;
  }

  return materialPlanItem.externalWorkStation.workStation.code === workStationCode;
}

/**
 * Checks if an materialPlanItem doesn't have a workstation or the workstation is not known to Tracy.
 * In this case, this item should be shown under a dropdown since it won't show up anywhere else.
 *
 * @param materialPlanItem
 */
export const isUnknownWorkstation = (materialPlanItem) => {
  if (materialPlanItem.externalWorkStation.isNew) {
    return true;
  }

  if (materialPlanItem.externalWorkStation.workStation.isNew) {
    return true;
  }

  return false;
}

const getScannedBatches = (materialPlanItem) => {
  // NOTE: this function only returns batches that are saved!
  const batches = []
  // eslint-disable-next-line
  for (const detailMaterialtask of materialPlanItem.details.models) {
    if (detailMaterialtask.detail.components.length > 0) {
      // Actual step performance
      // eslint-disable-next-line
      for (const component of detailMaterialtask.detail.components.models) {
        if (component.batchUsings.at(0).usedBatch.batchType.articleType.id === materialPlanItem.articleType.id) {
          batches.push(component.batchUsings.at(0).usedBatch)
        }
      }
    } else {
      // Intermediate save
      batches.push(...detailMaterialtask.batches)
    }
  }
  return batches
}

const initMaterialsTasksData = (materialPlanItems, step, formData, type) => {
  materialPlanItems
    .filter(item => item.type === type && (isMyWorkstation(step.workStation.code, item) || isUnknownWorkstation(item)))
    .forEach(item => {
      // Previous performance or intermediate save
      if (item.details.length > 0) {
        const batches = getScannedBatches(item)
        if (type === 'material' && batches.length > 0) {
          formData[item.id] = batches[0].id ? true : batches
        } else {
          formData[item.id] = item.details.at(0).value
        }
      } else {
        formData[item.id] = type === 'task' ? item.backflush : false
      }
    })
}


/**
 * Adapter which shows the MaterialPlan in a nice way in the form fields
 */
@observer
export class MaterialPlan extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    step: PropTypes.instanceOf(Step).isRequired,
    operator: PropTypes.instanceOf(Operator).isRequired,
    type: PropTypes.string.isRequired,
    colSpan: PropTypes.number.isRequired,
    targetProps: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    required: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    generalErrors: PropTypes.array,
    batchSize: PropTypes.number.isRequired,
    onPrintProgress: PropTypes.func.isRequired,
    disableTaskPrintButton: PropTypes.bool.isRequired,
    variableQuantity: PropTypes.number.isRequired,
    fieldRequired: PropTypes.bool.isRequired,
  }

  // VBH alert, because of some weird filtering 5 levels up, the material plan does not contain all material plan lines
  // So, make a local copy, and do another fetch.
  @observable localMaterialPlan

  constructor(props) {
    super(props);

    this.localMaterialPlan = new BillOfMaterialVersion(
      { id: props.productionRequest.productionOrder.materialPlan?.id },
      {
        relations: [
          'items.articleType.batchTypes',
          'items.articleType.storageLocations.storageLocation.warehouse',
          'items.articleType.storageLocations.warehouse',
          'items.shopOrderRoutingStep',
          'items.externalWorkStation.workStation',
          'items.details.billOfMaterialItem',
          'items.details.detail.components.productionRequest',
          'items.details.detail.components.batchUsings.usedBatch.batchType.articleType',
          'items.details.detail.components.batchUsings.usedBatch.storageLocation',
        ],
      },
    )
  }

  componentDidMount() {
    const { step, type, value, productionRequest } = this.props
    if (!this.localMaterialPlan.isNew) {
      this.localMaterialPlan.setFetchParams({
        where: `items.details(production_request=${productionRequest.id})`,
      })
      this.localMaterialPlan.fetch().then((_) => {
        // Fill in data form value for bom items which has been already scanned in previous steps
        initMaterialsTasksData(this.localMaterialPlan.items, step, value, type)
      })
    }
  }


  render() {
    const { productionRequest, step, type, colSpan, onChange, generalErrors, getErrors,
      batchSize, quantityTodo, required, value, targetProps, onConfirm, onPrintProgress,
      disableTaskPrintButton, variableQuantity, fieldRequired, operator,
    } = this.props

    if (
      this.localMaterialPlan.isNew ||
      this.localMaterialPlan.items.filter(item => item.type === type && (isMyWorkstation(step.workStation.code, item) || isUnknownWorkstation(item))).length === 0
    ) {
      return (
        <tr>
          <td data-test-material-plan-not-available={type}>{t('formStepField.field.materialPlan.notAvailable')}</td>
        </tr>
      )
    }
    return (
      <tr>
        <td colSpan={colSpan}>
          <hr />
          {type === 'task' &&
            <MaterialPlanTasksTable
              materialPlan={this.localMaterialPlan}
              step={step}
              value={value}
              onChange={onChange}
              getErrors={getErrors}
              generalErrors={generalErrors}
              required={required}
              productionRequest={productionRequest}
              batchSize={batchSize}
              quantityTodo={quantityTodo}
              targetProps={targetProps}
              onConfirm={onConfirm}
              onPrintProgress={onPrintProgress}
              disableTaskPrintButton={disableTaskPrintButton}
              operator={operator}
            />}
          {type === 'material' &&
            <MaterialPlanMaterialsTable
              materialPlan={this.localMaterialPlan}
              step={step}
              value={value}
              onChange={onChange}
              getErrors={getErrors}
              generalErrors={generalErrors}
              required={required}
              productionRequest={productionRequest}
              batchSize={batchSize}
              quantityTodo={quantityTodo}
              targetProps={targetProps}
              onConfirm={onConfirm}
              variableQuantity={variableQuantity}
              fieldRequired={fieldRequired}
            />}
        </td>
      </tr>
    )
  }
}

